
// init fancybox
$( '[data-fancybox]' ).fancybox({
    closeExisting: true,
    protect: true,
    animationEffect: 'fade',
    animationDuration: 600,
    touch: false
});

$( document ).ready( function() {

	/* - - - - - - - - - - - - - -       init inputmask       - - - - - - - - - - - - - - - */
	// $( '[type="tel"]' ).inputmask( '+38 (999) 999 99 99' );

	var elements = document.getElementsByClassName('phone_input');
	var maskOptions = {
		mask: '+373#0000000',
		definitions: {
			'#': /[1-9]{1}/
		}
	};
	for (const element of elements) {
		var mask = IMask(element, maskOptions);
		console.log(element)
	}

	function changeRadioBorderColor() {
		var selectedRadio = $('input[name="type_cooperation"]:checked');
		$('.cooperation_us-radio').css('border-color', ''); // Спочатку скидаємо колір границі всіх радіокнопок
		selectedRadio.closest('.cooperation_us-radio').css('border-color', '#d00a10');
	  }
	
	  $('input[name="type_cooperation"]').change(function() {
		changeRadioBorderColor();
	  });
	
	  changeRadioBorderColor();

	/* - - - - - - - - - - - - - -       init slick       - - - - - - - - - - - - - - - */
	$( '.partners__items' ).slick({
		infinite: true,
		arrows: false,
		dots: false,
		swipeToSlide: true,
		slidesToShow: 8,
		responsive: [
			{
				breakpoint: 1439,
				settings: {
					slidesToShow: 6,
					autoplay: true,
					autoplaySpeed: 3000
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					autoplay: true,
					autoplaySpeed: 3000
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
					autoplay: true,
					autoplaySpeed: 2000
				}
			}
		]
	});


	$(".promotion-categories__slider").slick({
		slidesToScroll: 1,
		swipeToSlide: true,
		variableWidth: true,
		infinite: false,
		arrows: false,
		dots: false,
		slidesToShow: 7,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 375,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					variableWidth: false,
					swipeToSlide: false,
				}
			}
		]
	});
	
});